import React from "react";
import AboutImg from '../assets/images/1 main.png';
import ViewMyWorkBtn from "./ViewMyWorkBtn";

function AboutSection(){
    return (
        <div className="bg-dark text-light py-5"  id="about">
            <div className="container">

                <div className="flex-column-reverse flex-md-row row">

                    <div className="col-md-6 mt-md-0 d-flex flex-column justify-content-center align-items-center">

                        <img className="img-fluid w-50 shadow" src={AboutImg} alt="about" />

                    </div>
                    <div className="col-md-6 d-flex flex-column justify-content-center">
                        <h2 className="mb-5" > About </h2>
                        <p className="text-start">
                        This app is designed as a pocket trainer for English learners. It offers best practices when someone is learning new language. It has sections like vocabulary, grammar, every english conversations, free time listening and test exercises to check yourself. 
                        </p>
                        <p className="text-start mb-5">
                        In our modern life, time is the most expensive aspect. On the other hand, If you want to reach your full potential in international market in any field, in business, education, media or in sports. learning language, especially English, is also important.  With the help of our app, you can learn English on the go and reach your full potential!
                        </p>
                        
                        <ViewMyWorkBtn/>

                    </div>



                </div>

            </div>


        </div>

    )
}

export default AboutSection;