import React from "react";

function ViewMyWorkBtn() {
    return (
        <a href="#features">
            <button className="btn btn-primary shadow text-text-capitalize">
                View App Features 
            </button>
        </a>
    )
}

export default ViewMyWorkBtn;

