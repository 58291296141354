import React from "react";
import HeaderImg from '../assets/images/main.png'
import { AppStoreButtons } from "./AppStoreButtons";

function Header(){
    return (
        <header className="pt-5" id="header">
            <div className="container py-md-5">
                <div className="row">
                    <div className="col-md-6 d-flex flex-column align-items-start justify-content-center mt-5 mt-md-0">
                        <h3 className="text-secondary fw-bold lh-1">Master your English </h3>
                        <h1 className="text-capitalize text-start text-primary lh-1 mb-5" > English - <br />Trainer App</h1>
                        <AppStoreButtons/>
                        <br/>
                        

                    </div>
                    <div className="col-md-6 d-flex justify-content-center justify-content-md-end">
                        <img className="img-fluid w-75  shadow my-5" src={HeaderImg} alt="Header" />



                    </div>
                </div>
            </div>
            
        </header>
    )
}

export default Header;