import './App.css';
import PrivacyPolicyPage from './components/PrivacyPolicyPage';
import { Route } from 'react-router-dom';
import { Routes } from 'react-router-dom';
import Home from './components/Home';

function App() {
  return (
    <div className="App">

<Routes>
            <Route path="/" element={<Home />} />
            <Route path="/privacy" element={<PrivacyPolicyPage />} />
          
         </Routes>
    </div>
  );
}

export default App;
