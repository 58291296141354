import React from "react";


import NavLink from "react-bootstrap/NavLink";

import NavbarBrand from "react-bootstrap/NavbarBrand";

import NavbarCollapse from "react-bootstrap/NavbarCollapse";

import NavbarToggle from "react-bootstrap/NavbarToggle";



import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";


function NavigationBar() {
    return (
        <Navbar expand='lg' className="bg-primary w-100 fixed-top z-1" data-bs-theme='dark' >
            <Container>

                <NavbarBrand href="#" className="fs-4">KarmanTrener <span className="text-dark" >.COM</span> </NavbarBrand>
                <NavbarToggle aria-controls="basic-navbar-nav" />
                <NavbarCollapse id="basic-navbar-nav">
                    <Nav className="me-auto w-100 d-flex justify-content-end">
                        <NavLink href="#header">Home</NavLink>
                        <NavLink href="#about">About</NavLink>
                        <NavLink href="#features">Features</NavLink>
                        <NavLink href="#contacts">Contact</NavLink>
                    </Nav>

                </NavbarCollapse>


            </Container>

        </Navbar>
    )
}

export default NavigationBar;