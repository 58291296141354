import ProjectImg1 from './images/1 main.png';
import ProjectImg2 from './images/2 levels.png';
import ProjectImg3 from './images/3 quiz.png';
import ProjectImg4 from './images/4 ersult.png';
import ProjectImg5 from './images/5 review.png';
import ProjectImg6 from './images/6 tests.png';
import ProjectImg7 from './images/7 everydayENglish.png';
import ProjectImg8 from './images/8 dictionary.png';

const ProjectItems = [

    {
        id: 1,
        projectImg: ProjectImg1,
        projectTitle: "Sections",
        projectDescription: "All exercises under each section are belong to LEVEL selected above. Exercises under each section will change when new level is selected."
    },

    {
        id: 2,
        projectImg: ProjectImg2,
        projectTitle: "Levels",
        projectDescription: "Your can learn and practice your English level by level. Levels are organized to start from easier to harder."
    },

    {
        id: 3,
        projectImg: ProjectImg3,
        projectTitle: "Take a Quiz",
        projectDescription: "Check yourself by taking quizzes. It will help you know your status. Quote says: \"Practice makes progress!\""
    },

    {
        id: 4,
        projectImg: ProjectImg4,
        projectTitle: "Quiz Result",
        projectDescription: "When you finish solving a quiz, app will show you the success rate by percentage. You can also see count of corrects and mistakes you answered."
    },

    {
        id: 5,
        projectImg: ProjectImg5,
        projectTitle: "Review Answers",
        projectDescription: "Check whole your answers you have selected during the quiz and learn from your mistakes"
    },
    
    {
        id: 6,
        projectImg: ProjectImg6,
        projectTitle: "Test Results",
        projectDescription: "Check your overall progress and you can retake the quiz if you think you need more practice."
    },
    
    {
        id: 7,
        projectImg: ProjectImg7,
        projectTitle: "Everyday English",
        projectDescription: "Listen and practive every day English conversations from real life scenarios. "
    },
    
    {
        id: 8,
        projectImg: ProjectImg8,
        projectTitle: "New Words",
        projectDescription: "On every unit, you will learn new words by spelling, translation, example. Grow your vocabulary. "
    }
    


]

export default ProjectItems;
