import React from "react";
import { NavLink } from "react-router-dom";
import PrivacyPolicyContent from "./PrivacyPolicyContent";

function PrivacyPolicyPage() {
    return (

      <div className="d-flex flex-column justify-content-center">
      
      
      <NavLink className="mb-2" to="/">Go To Home Page</NavLink>

      <PrivacyPolicyContent />

  </div>
        
    )
}

export default PrivacyPolicyPage;

