import { AppStoreButton, ButtonsContainer, GooglePlayButton } from "react-mobile-app-button";


    
export const AppStoreButtons = () => {
  
  const APKUrl = "https://play.google.com/store/apps/details?id=com.karmantrener.english";
   const IOSUrl = "https://apps.apple.com/us/app/expo-go/id982107779";

  return (
    <ButtonsContainer>
      <GooglePlayButton
      height={55}
        url={APKUrl}
        theme={"dark"}
      />

      <AppStoreButton
      height={55}
        url={IOSUrl}
        theme={"dark"}
      />
      
    </ButtonsContainer>
  );
  
};