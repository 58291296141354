import React from "react";
import Card from "react-bootstrap/Card";
import CardImg from "react-bootstrap/CardImg";
import CardBody from "react-bootstrap/CardBody";
import CardTitle from "react-bootstrap/CardTitle";
import CardText from "react-bootstrap/CardText";

function ProjectCard(props) {
    return (
        <div className="col-md-3">
            <Card className="h-100 shadow">
                <CardImg variant="top"   src={props.projectImage} />
                <CardBody className="p-5">
                    <CardTitle className="fw-bold mb-4 fs-3">
                        {props.projectName}
                    </CardTitle>
                    <CardText> {props.projectDesc} </CardText>
                </CardBody>
            </Card>
        </div>
    )
}

export default ProjectCard;