import React from "react";

import NavigationBar from './NavigationBar';
import Header from './Header';
import AboutSection from './AboutSection';
import ProjectsSection from './ProjectsSection';
import ContactSection from './ContactSection';
import Footer from './Footer';

function Home() {
    return (
      <div >
  
        <NavigationBar />
        <Header />
        <AboutSection />
        <ProjectsSection />
        <ContactSection />
        <Footer />
  
      </div>
    );
  }
  
  export default Home;